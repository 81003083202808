import { NgClass, NgTemplateOutlet } from '@angular/common'
import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core'

import { FacebookAccount } from '~features/integrations/models/facebook-account.model'

import { AvatarComponent } from '../../ui/avatar/avatar.component'
import { TextComponent } from '../../ui/text/text.component'

@Component({
  selector: 'sb-facebook-account',
  template: `<div
    [ngClass]="{ 'menu-item': isMenuItem }"
    class="flex w-full flex-col items-center justify-start md:flex-row md:justify-between"
  >
    <div class="flex w-full items-center md:w-auto">
      <sb-avatar
        size="sm"
        [src]="facebookAccount?.picture_url"
        [title]="facebookAccount?.first_name + ' ' + facebookAccount?.last_name"
      />
      <div class="px-1.5">
        <sb-text variant="bodyMedium" noMargin
          >{{ facebookAccount?.first_name }} {{ facebookAccount?.last_name }}</sb-text
        >
        <!-- Category -->
        <sb-text variant="label" noMargin>{{ facebookAccount?.email }}</sb-text>
      </div>
    </div>
    @if (rightSideTemplateRef) {
      <ng-container [ngTemplateOutlet]="rightSideTemplateRef"></ng-container>
    }
  </div> `,
  styles: [
    `
      :host {
        display: inline-block;
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, AvatarComponent, TextComponent, NgTemplateOutlet],
})
export class FacebookAccountComponent {
  @Input() facebookAccount: FacebookAccount
  @Input() isActive = false
  @Input() isMenuItem = false
  @ContentChild('rightSideTemplate') rightSideTemplateRef: TemplateRef<unknown>
}
