import { Injectable } from '@angular/core'

import { environment } from '~env'

@Injectable({
  providedIn: 'root',
})
export class LinkedinApiService {
  defaultScopes = environment.linkedinScopes

  checkScopes(requiredScopes: string[], grantedScopes: string[]): void {
    const intersection = requiredScopes.filter((requestedScope) => grantedScopes.includes(requestedScope))
    if (intersection.length !== requiredScopes.length) {
      throw new Error('Per accedere alle funzionalità di Sbam devi concedere tutti i permessi richiesti')
    }
  }

  login(redirectUri: string, scopes: string[] = this.defaultScopes) {
    const baseUrl = 'https://www.linkedin.com/oauth/v2/authorization?'
    const parameters = [
      `client_id=${environment.linkedinAppKey}`,
      'response_type=code',
      `scope=${scopes.join(',')}`,
      `redirect_uri=${encodeURIComponent(redirectUri)}`,
    ]
    window.location.href = baseUrl + parameters.join('&')
    return
  }
}
