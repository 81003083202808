import { NgStyle } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { TranslocoModule } from '@jsverse/transloco'

import { Icon } from '~common/icons'
import { FacebookAccount } from '~features/integrations/models/facebook-account.model'

import { ButtonComponent } from '../../ui/button/button.component'
import { TextComponent } from '../../ui/text/text.component'
import { FacebookAccountComponent } from '../facebook-account/facebook-account.component'

@Component({
  selector: 'sb-facebook-account-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoModule,
    MatListModule,
    FacebookAccountComponent,
    ButtonComponent,
    TextComponent,
    MatIconModule,
    NgStyle,
  ],
  template: `
    <ng-container *transloco="let t">
      @for (facebookAccount of facebookAccounts; track facebookAccount; let last = $last) {
        <mat-list>
          <mat-list-item>
            <sb-facebook-account [facebookAccount]="facebookAccount">
              <ng-template #rightSideTemplate>
                <div class="mt-5 flex flex-wrap items-center justify-center md:mt-0">
                  <!-- Make primary -->
                  @if (enableMakePrimary && !facebookAccount.is_primary) {
                    <sb-button (sbClick)="onMakePrimaryFacebookAccount(facebookAccount)" [icon]="Icons.Facebook">
                      {{ t('actions.UseAsLoginAccount') }}
                    </sb-button>
                  }
                  @if (enableMakePrimary && facebookAccount.is_primary) {
                    <sb-text class="px-6" variant="labelLarge" muted truncate noMargin>
                      <div class="flex items-center">
                        <mat-icon
                          [svgIcon]="Icons.Facebook"
                          [ngStyle]="{ width: '20px', heigth: '20px' }"
                          class="mr-2"
                        />
                        {{ t('info.LoginAccount') }}
                      </div>
                    </sb-text>
                  }
                  <!-- Refresh token -->
                  <sb-button (sbClick)="onRefreshFacebookAccount(facebookAccount)" variant="text" [icon]="Icons.Cached">
                    {{ t('actions.RefreshAuth') }}
                  </sb-button>
                  <!-- Delete -->
                  @if (enableDelete) {
                    <sb-button
                      (sbClick)="onDeleteFacebookAccount(facebookAccount)"
                      variant="text"
                      themeColor="warn"
                      [icon]="Icons.Delete"
                    >
                      {{ t('actions.Delete') }}
                    </sb-button>
                  }
                </div>
              </ng-template>
            </sb-facebook-account>
          </mat-list-item>
        </mat-list>
      }
    </ng-container>
  `,
})
export class FacebookAccountListComponent {
  @Output() deleteFacebookAccount: EventEmitter<FacebookAccount> = new EventEmitter<FacebookAccount>()
  @Input() enableDelete = false
  @Input() enableMakePrimary = false
  @Input() facebookAccounts: FacebookAccount[]
  @Output() makePrimaryFacebookAccount: EventEmitter<FacebookAccount> = new EventEmitter<FacebookAccount>()
  @Output() refreshFacebookAccount: EventEmitter<FacebookAccount> = new EventEmitter<FacebookAccount>()
  protected readonly Icons = Icon

  onDeleteFacebookAccount(facebookAccount: FacebookAccount): void {
    this.deleteFacebookAccount.emit(facebookAccount)
  }

  onMakePrimaryFacebookAccount(facebookAccount: FacebookAccount): void {
    this.makePrimaryFacebookAccount.emit(facebookAccount)
  }

  onRefreshFacebookAccount(facebookAccount: FacebookAccount): void {
    this.refreshFacebookAccount.emit(facebookAccount)
  }
}
