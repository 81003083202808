import { AsyncPipe } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { MatToolbarModule } from '@angular/material/toolbar'
import { RouterLinkActive, RouterLink } from '@angular/router'
import { TranslocoService, TranslocoModule } from '@jsverse/transloco'
import { Store } from '@ngrx/store'
import { map, Observable } from 'rxjs'

import { SidebarItem, SidebarItemSlim } from '~common/layout/sidebar/sidebar.component'
import { ButtonVariant } from '~common/ui/button/button.component'
import { UserService } from '~core/services'
import { DialogService } from '~core/services/ui/dialog.service'
import { selectUrl } from '~core/store/core.selectors'
import { UpgradeDialogComponent } from '~features/subscription/components/upgrade-dialog/upgrade-dialog.component'

import { ButtonComponent } from '../../ui/button/button.component'

export interface NavigationBarItem {
  exact?: boolean
  label?: string
  labelPath?: string
  // ⚠ Path must be in absolute format
  path: string
  perms?: string[]
}

@Component({
  selector: 'sb-navbar',
  template: `
    <ng-container *transloco="let t; read: 'navigation'">
      <mat-toolbar class="navigation-bar">
        <mat-toolbar-row>
          @for (item of items; track item) {
            <a
              (click)="!hasPermissions(item) ? openUpgradeDialog() : null"
              class="nav-link"
              [routerLink]="hasPermissions(item) ? item.path : null"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              @if (currentUrl$ | async; as url) {
                <sb-button
                  [variant]="
                    (item.exact === undefined || item.exact ? url === item.path : url.startsWith(item.path))
                      ? itemVariantActive
                      : itemVariant
                  "
                >
                  {{ item.label ?? t(item.labelPath) }}
                </sb-button>
              }
            </a>
          }
        </mat-toolbar-row>
      </mat-toolbar>
    </ng-container>
  `,
  styles: [
    `
      @use 'variables' as var;

      mat-toolbar.navigation-bar {
        background-color: transparent;
        overflow: auto;
        padding-left: 2px; // Solves the issue of the first item's shadow being cut off on hover

        mat-toolbar-row {
          height: var.$navbar-height;
          padding: var.$navbar-padding;
          line-height: initial;
          gap: var.$navbar-item-space-between;

          a.nav-link {
            border-radius: var.$button-radius;

            &.active {
              pointer-events: none;
            }
          }
        }
      }
    `,
  ],
  standalone: true,
  imports: [TranslocoModule, MatToolbarModule, RouterLinkActive, RouterLink, ButtonComponent, AsyncPipe],
})
export class NavbarComponent implements OnInit {
  currentUrl$: Observable<string>
  @Input() itemVariant: ButtonVariant = 'tonal'
  @Input() itemVariantActive: ButtonVariant = 'filled'
  @Input() items: NavigationBarItem[]

  constructor(
    private store: Store,
    private translateService: TranslocoService,
    private dialogService: DialogService,
    private userService: UserService,
  ) {}

  hasPermissions(item: SidebarItem | SidebarItemSlim) {
    return item.perms?.length ? this.userService.hasAllCapabilities(item.perms) : true
  }

  ngOnInit(): void {
    this.currentUrl$ = this.store.select(selectUrl).pipe(map((url) => url.split('?')[0]))
  }

  openUpgradeDialog() {
    const data = {
      title: this.translateService.translate('alerts.genericUpgrade.Title'),
      content: this.translateService.translate('alerts.genericUpgrade.Body'),
    }
    this.dialogService.open(UpgradeDialogComponent, { data, width: '835px' })
    return
  }
}
