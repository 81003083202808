import { Inject, Injectable } from '@angular/core'
import Rollbar from 'rollbar'

import { RollbarService } from '~core/services/rollbar.service'
import logger from '~core/utils/logger'
import { environment } from '~env'

@Injectable({ providedIn: 'root' })
export class FacebookApiService {
  defaultScopes = environment.facebookScopes

  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  checkScopes(requiredScopes: string[], grantedScopes: string[]): void {
    const intersection = requiredScopes.filter((requestedScope) => grantedScopes.includes(requestedScope))
    if (intersection.length !== requiredScopes.length) {
      this.rollbar.info('Missing required scopes', { grantedScopes, requiredScopes })
      throw new Error('Per accedere alle funzionalità di Sbam devi concedere tutti i permessi richiesti')
    }
  }

  login(redirectUri: string, authType = 'rerequest,reauthenticate', scopes: string[] = this.defaultScopes): void {
    const baseUrl = `https://www.facebook.com/${environment.facebookApiVersion}/dialog/oauth?`
    const parameters = [
      `client_id=${environment.facebookAppId}`,
      'response_type=code,granted_scopes',
      `scope=${scopes.join(',')}`,
      `redirect_uri=${encodeURIComponent(redirectUri)}`,
      ...(authType ? [`auth_type=${authType}`] : []),
    ]
    logger.debug('FacebookApiService.login', { baseUrl, parameters })
    window.location.href = baseUrl + parameters.join('&')
    return
  }
}
